import React from "react";
import Header from "../../header";
import Social from "../../sections/social";
import MailNotifications from "../../sections/mail-notifications";
import Footer from "../../footer";

const Main = ({ children }) => (
  <div>
    <Header />
    {children}
    <Social />
    <MailNotifications />
    <Footer />
  </div>
);

export default Main;
