import React from 'react';
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import clsx from 'clsx';

import "./Modal.styles.scss";
import { useEffect } from 'react';
import { CssTransition } from '../transition';

const Modal = ({ show, children, onClose, className }) => {
  useEffect(() => {
    document.body.classList.toggle('modal-open', show)
  }, [show]);

  return (
    <CssTransition
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={400}
    >
      <div className={clsx('modal', className)}>
        <div className="modal__inner">
        <div 
          onClick={onClose}
          className='modal__overlay' 
        />
          <div className="modal__dialog">
            <button 
              onClick={onClose}
              className='modal__close-button'
            >
              <CloseIcon />
            </button>
            {children}
          </div>
        </div>
      </div>
    </CssTransition>
  )
}

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClose: PropTypes.func,
  className: PropTypes.string
};


export default Modal