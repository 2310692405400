import React from "react";

import { useCopyToClipboard } from "../../../helpers/useCopyToClipboard";

import { ReactComponent as Twitter } from "../../../assets/svg/social/twitter.svg";
import { ReactComponent as Discord } from "../../../assets/svg/social/discord.svg";
import { ReactComponent as Medium } from "../../../assets/svg/social/medium.svg";

import "./styles.scss";

const Social = () => {
  const [copied, copy] = useCopyToClipboard("metaportal@enterdao.xyz");

  return (
    <div className="social-wrapper">
      <section className="section">
        <h5>If you need more information Contact us or Join our Socials</h5>
        <div className="input-wrapper" onClick={copy}>
          <p>metaportal@enterdao.xyz</p>
          <button className={copied ? "copied gradient" : "gradient"}>
            <span>{copied ? "Copied" : "Copy"}</span>
          </button>
        </div>
        <ul>
          <li>
            <a
              className="secondary"
              href="https://twitter.com/metaportal_gg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
          </li>
          <li>
            <a
              className="secondary"
              href="https://t.co/UBokVKGtwj"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Discord />
            </a>
          </li>
          <li>
            <a
              className="secondary"
              href="https://medium.com/enterdao"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Medium />
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Social;
