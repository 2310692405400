import React from "react";

import "./styles.scss";

// Pure CSS Select Box
const Select = ({ options, selected, onChange }) => {

  const buildOptionName = (version) => `Release: v.${version}`

  return (
    <div className="select-box">
      <div className="select-box__current" tabIndex="1">
        {
          options.map(option => (
            <div key={option} className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id={option}
                value={option}
                name="notes"
                readOnly
                checked={selected.version === option}
              />
              <p className="select-box__input-text">{buildOptionName(option)}</p>
            </div>
          ))
        }
        <div className="select-box__icon" aria-hidden="true"/>
      </div>
      <ul className="select-box__list">
        {
          options.map(option => (
            <li key={option} onClick={() => onChange(option)}>
              <label className="select-box__option" htmlFor={option} aria-hidden>{buildOptionName(option)}</label>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default Select;
