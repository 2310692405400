import React from 'react'
import clsx from 'clsx';
import './Button.styles.scss';

const Button = ({size, variant, color, children, className, ...otherProps}) => {
  return (
    <button 
      className={
        clsx("btn", {
          [`btn--size-${size}`]: size,
          [`btn--variant-${variant}`]: variant,
          [`btn--color-${color}`]: color,
        }, className)
      }
      {...otherProps}
    >
      {children}
    </button>
  )
}

export default Button