import React, { useState } from "react";
import Subtitle from "../../common/subtitle";

import "./styles.scss";

const MailNotifications = () => {
  const [email, setEmail] = useState("");

  return (
    <section className="mail-notifications">
      <div className="content">
        <div className="mail-notifications-wrapper">
        <Subtitle color="light" emoji="&#128526;" text="STAY UP to date" />
        <h3>Receive Latest Updates</h3>
        <div className="input-wrapper">
          <form
            action="https://xyz.us5.list-manage.com/subscribe/post"
            method="POST"
            noValidate
            target="_blank"
          >
            <input
              type="hidden"
              name="u"
              value="8746db1bbacb6ab8a19400196"
            />
            <input type="hidden" name="id" value="33f89e7834" />
            <input
              placeholder="Enter your email"
              type="email"
              name="EMAIL"
              id="MERGE0"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
            />
            <button type="submit" className="gradient large">
              <span>Notify me</span>
            </button>
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
              aria-label="Please leave the following three fields empty"
            >
              <label htmlFor="b_email">Email: </label>
              <input
                type="email"
                name="b_email"
                tabIndex={-1}
                value=""
                placeholder="youremail@gmail.com"
                id="b_email"
              />
            </div>
          </form>
        </div>
        </div>
      </div>
    </section>
  );
};

export default MailNotifications;
