import React, { useEffect } from "react";
import Main from "../../components/common/main";
import Title from "../../components/sections/title";
import Highlights from "../../components/sections/highlights";
import Bridge from "../../components/sections/bridge";
import UpcomingFeatures from "../../components/sections/upcoming-features";
import Faq from "../../components/sections/faq";
import BottomDownloads from "../../components/sections/bottom-downloads";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Home = () => {
  useEffect(() => {
    if (document.fonts) {
      document.fonts.ready
        .then(ScrollTrigger.refresh)
        .catch((e) => {});
    }

    window.addEventListener("load", ScrollTrigger.refresh);

    return () => {
      window.removeEventListener("load", ScrollTrigger.refresh); 
    };
  }, []);

  return (
    <Main>
      <Title />
      <Highlights />
      <Bridge />
      <UpcomingFeatures />
      <Faq />
      <BottomDownloads />
    </Main>
  )
};

export default Home;
