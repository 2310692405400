import React, { createContext, useContext, useMemo, useState } from "react";
import InstallationModal from ".";

const InstallationModalContext = createContext();

export const useInstallationModal = () => {
  return useContext(InstallationModalContext);
}

export const InstallationModalProvider = ({children}) => {
  const [installationModalState, setInstallationModalState] = useState({
    opened: false,
    os: 'mac'
  });

  const controller = useMemo(() => {
    const openInstallationModal = (os) => {
      setInstallationModalState({
        opened: true,
        os
      })
    }

    const openMacInstallationModal = () => {
      openInstallationModal('mac');
    }

    const openWindowsInstallationModal = () => {
      openInstallationModal('windows');
    }

    const closeInstallationModal = () => {
      setInstallationModalState(prevState => ({
        ...prevState,
        opened: false,
      }))
    }

    return {
      openMacInstallationModal,
      openWindowsInstallationModal,
      closeInstallationModal
    }
  }, []);

  const value = useMemo(() => {
    return {
      ...installationModalState,
      ...controller
    }
  }, [controller, installationModalState])

  return (
    <InstallationModalContext.Provider value={value}>
      {children}
      <InstallationModal />
    </InstallationModalContext.Provider>
 )
}
