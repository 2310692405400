import React, { useEffect, useRef } from "react";
import { Power1, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import subscriptionBackground from "../../../assets/img/mail-notify-background.png";
import appHomeImage from "../../../assets/img/app-home.png";
import DownloadLinksRow from '../../common/download-links-row';

import "./styles.scss";

const BottomDownloads = () => {

  const bottomDownloadsRef = useRef();
  const tl = useRef({});
  const q = gsap.utils.selector(bottomDownloadsRef);
  const width = window.innerWidth;
  const breakpoint = 1023;

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (width > breakpoint) {
      const trigger = bottomDownloadsRef.current;

      tl.current = gsap.timeline().fromTo(
        q(".app-screenshot"),
        {
          y: -70,
        },
        {
          y: 0,
          duration: 0.5,
          ease: Power1.easeOut,
          scrollTrigger: {
            trigger: trigger.querySelector(".bottom-downloads"),
            start: "top center",
            end: "bottom center",
            scrub: "true",
          },
        }
      );
    }
  });
  return (
    <section className="section-reset" ref={bottomDownloadsRef}>
      <div className="content">
        <div className="bottom-downloads">
          <img
            src={subscriptionBackground}
            width={877}
            height={631}
            alt="background"
            className="visuals"
          />
          <img
            src={appHomeImage}
            width={2880}
            height={1800}
            className="app-screenshot"
            alt="MetaPortal is coming soon"
          />
        </div>
        <DownloadLinksRow/>
      </div>
    </section>
  );
};

export default BottomDownloads;
