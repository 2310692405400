import React from "react";
import startImageUrl from "../../assets/img/installation-flow/start.png";
import macImageUrl1 from "../../assets/img/installation-flow/mac-1.png";
import macImageUrl2 from "../../assets/img/installation-flow/mac-2.png";
import macImageUrl3 from "../../assets/img/installation-flow/mac-3.png";
import macImageUrl4 from "../../assets/img/installation-flow/mac-4.png";
import windowsImageUrl1 from "../../assets/img/installation-flow/windows-1.png";
import windowsImageUrl2 from "../../assets/img/installation-flow/windows-2.png";
import windowsImageUrl3 from "../../assets/img/installation-flow/windows-3.png";
import windowsImageUrl4 from "../../assets/img/installation-flow/windows-4.png";

const startStep = {
  title: (
    <>
      You Successfully
      <br />
      Downloaded the Application!
    </>
  ),
  image: startImageUrl,
  description: (
    <p>
      If you have issues with launching the app, please follow the steps to
      learn how to do it.
    </p>
  ),
};

const macSteps = [
  startStep,
  {
    title: "Go to Finder",
    image: macImageUrl1,
    description: (
      <p>
        Find and select <b>Finder</b> on your deck
      </p>
    ),
  },
  {
    title: "Select MetaPortal in Applications ",
    image: macImageUrl2,
    description: (
      <ul>
        <li>
          Find <b>Applications</b> in the navigation bar on the left, then
          search for MetaPortal
        </li>
        <li>
          <b>Control-click</b> the app icon, then choose Open from the shortcut
          menu
        </li>
      </ul>
    ),
  },
  {
    title: "Warning Modal Appears",
    image: macImageUrl3,
    description: (
      <ul>
        <li>
          Click <b>OK</b> on the warning modal that appears
        </li>
      </ul>
    ),
  },
  {
    title: "Open MetaPortal",
    image: macImageUrl4,
    description: (
      <ul>
        <li>
          From the shortcut menu click <b>Open</b> again
        </li>
        <li>
          When the same Warning Modal appears, click Open and the application
          should start
        </li>
        <li>That’s all, enjoy!</li>
      </ul>
    ),
  },
];
 
const windowsSteps = [
  startStep,
  {
    title: "Go to Settings",
    image: windowsImageUrl1,
    description: (
      <ul>
        <li>
          From the <b>Windows</b> menu find and select Settings
        </li>
      </ul>
    ),
  },
  {
    title: "Select Apps",
    image: windowsImageUrl2,
    description: (
      <ul>
        <li>
          From the navigation bar on the left find and select <b>Apps</b>
        </li>
      </ul>
    ),
  },
  {
    title: "Choose Apps & features",
    image: windowsImageUrl3,
    description: (
      <ul>
        <li>Check the options that appear on the right</li>
        <li>
          Find and select <b>Apps &amp; features</b> (the first option)
        </li>
      </ul>
    ),
  },
  {
    title: "Choose to get apps from ‘Anywhere’",
    image: windowsImageUrl4,
    description: (
      <ul>
        <li>
          From the menu find <b>Choose where to get apps</b>
        </li>
        <li>
          Select and switch the dropdown to <b>Anywhere</b>
        </li>
        <li>That’s all, enjoy!</li>
      </ul>
    ),
  },
];

export const installationSteps = {
  mac: macSteps,
  windows: windowsSteps
}