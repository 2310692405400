import React from "react";

import Subtitle from "../../common/subtitle";

import "./styles.scss";

const Bridge = () => (
  <section className="bridge">
    <div className="content-wrapper">
      <Subtitle
        color="accent"
        emoji="&#128640;"
        text="the path to web3"
        size="big"
      />
      <h3>
        A Central Piece of <strong>Infrastructure</strong>
      </h3>
      <p>
        MetaPortal provides game developers with the sorely needed
        infrastructure with the aim to further advance the web3 space and drive
        adoption.
      </p>
    </div>
  </section>
);

export default Bridge;
