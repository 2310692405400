import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const ExpandIcon = ({ expanded }) => (
  <div className={`expandable-icon ${expanded && "expanded"}`}>
    <div className="vertical"></div>
    <div className="horizontal"></div>
  </div>
);

ExpandIcon.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

ExpandIcon.defaultProps = {
  expanded: false,
};

export default ExpandIcon;
