import React from "react";
import { CSSTransition } from "react-transition-group";
import { useRef } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

export const CssTransition = forwardRef(({children, ...otherProps}, ref) => {
  const nodeRef = useRef(null);

  useImperativeHandle(ref, () => nodeRef.current);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      {...otherProps}
    >
      {React.isValidElement(children) ? (
        React.cloneElement(children, {
          ref: nodeRef,
        })
      ) : null}
    </CSSTransition>
  )
})