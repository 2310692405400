import React from 'react';
import clsx from 'clsx';
import './Badge.styles.scss';

const Badge = ({children, color, className}) => {
  return (
    <div
      className={clsx('badge', {
        [`badge--color-${color}`]: color
      }, className)}
    >
      {children}
    </div>
  )
}

export default Badge