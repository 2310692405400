import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/logos/metaportal-icon.svg";

import "./styles.scss";

export default function Footer() {
  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer>
      <div className="content">
        <ul className="nav">
          <li>
            <a
              href="https://enterdao.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              EnterDAO
            </a>
          </li>
          <li>
            <a
              href="https://landworks.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LandWorks
            </a>
          </li>
          <li>
            <a
              href="https://sharded-minds.enterdao.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sharded Minds
            </a>
          </li>
          <li>
            <a
              href="https://enterdao.gitbook.io/metaportal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation
            </a>
          </li>
          <li>
            <Link to="/release-notes">Release Notes</Link>
          </li>
          <li>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeWIncJCwgp-gzcriMZSwc1ntHiDjiMdXNrhEZrhtnC6qbPyg/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Request a game
            </a>
          </li>
        </ul>
        <Logo />
        <p>
          metaportal.gg
          {" "}
          &#xA9; {getYear()}
        </p>
      </div>
    </footer>
  );
}
