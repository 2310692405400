import React from "react";

import Subtitle from "../../common/subtitle";

import feature01 from "../../../assets/img/feature-01.png";
import feature02 from "../../../assets/img/feature-02.png";
import feature03 from "../../../assets/img/feature-03.png";

import "./styles.scss";

const UpcomingFeatures = () => {
  return (
    <section className="upcoming-features">
      <ul className="content">
        <li>
          <div className="img-wrapper">
            <img src={feature01} width={180} height={180} alt="Swapping" />
            <Subtitle color="accent" emoji="&#9203;" text="Coming later" />
          </div>
          <div className="details-wrapper">
            <h4>Fiat On-Ramping &amp; In-App Swaps</h4>
            <p>Buy and exchange crypto without leaving the app.</p>
          </div>
        </li>
        <li>
          <div className="img-wrapper">
            <img src={feature02} width={180} height={180} alt="Chat" />
            <Subtitle color="accent" emoji="&#9203;" text="Coming later" />
          </div>
          <div className="details-wrapper">
            <h4>Social</h4>
            <p>
              Playing with friends is more fun. Invite you friends, see their
              activity, and chat.
            </p>
          </div>
        </li>
        <li>
          <div className="img-wrapper">
            <img src={feature03} width={180} height={180} alt="NFT" />
            <Subtitle color="accent" emoji="&#9203;" text="Coming later" />
          </div>
          <div className="details-wrapper">
            <h4>NFT Marketplace</h4>
            <p>
              Check the newest collection. Buy and sell in-game items through
              the app.
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default UpcomingFeatures;
