import { gql } from "@apollo/client";

export const AUTHENTICATE = gql`
  mutation authenticateUserWithPassword($username: String, $password: String) {
    authenticateUserWithPassword(username: $username, password: $password) {
      token
      item {
        id
        name
        username
        email
        isAdmin
      }
    }
  }
`;

export const GET_DOWNLOADS_TODAY = gql`
query getList($where: AppDownloadWhereInput!) {
  allAppDownloads(where: $where) {
    id
    date
    totalDownloads
  }
}
`;

export const CREATE_DOWNLOAD_INPUT = gql`
mutation create($data: AppDownloadCreateInput!) {
  createAppDownload(data: $data) {
    id
  }
}
`;

export const UPDATE_DOWNLOAD_INPUT = gql`
mutation update($id: ID!, $data: AppDownloadUpdateInput) {
  updateAppDownload(id: $id, data: $data) {
    id
  }
}
`;

export const GET_ALL_RELEASES = gql`
{
  allReleases(sortBy:id_DESC) {
    version
    releaseNotes
   }
}`;
