import React from "react";

import { ReactComponent as Apple } from "../../../assets/svg/logos/apple.svg";
import { ReactComponent as Windows } from "../../../assets/svg/logos/windows.svg";
import { useInstallationModal } from "../../installation-modal";
import { updateDownloadsCount } from "../../../services/downloads-service";

import "./styles.scss";

const DownloadLink = ({title, href, onClick, children}) => {
  const handleClick = (e) => {
    updateDownloadsCount();

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <a
      className="primary"
      title={title}
      href={href}
      onClick={handleClick}
    >
      {children}
    </a>
  )
};

const DownloadLinksRow = () => {
  const installationModal = useInstallationModal();

  return (
    <div className="download-links-row">
      <DownloadLink
        title="Download for mac | intel"
        onClick={installationModal.openMacInstallationModal}
        href={process.env.REACT_APP_DOWNLOAD_LINK_MAC_INTEL}
      >
        <Apple /> Download for mac | intel
      </DownloadLink>
      <DownloadLink
        title="Download for mac | m1"
        onClick={installationModal.openMacInstallationModal}
        href={process.env.REACT_APP_DOWNLOAD_LINK_MAC_M1}
      >
        <Apple /> Download for mac | m1
      </DownloadLink>
      <DownloadLink
        title="Download for windows"
        onClick={installationModal.openWindowsInstallationModal}
        href={process.env.REACT_APP_DOWNLOAD_LINK_WINDOWS}
      >
        <Windows /> Download for Windows
      </DownloadLink>
    </div>
  );
};

export default DownloadLinksRow;
