import React from "react";
import ExpandIcon from "../expand-icon";

import "./styles.scss";

const AccordionItem = ({
  title,
  content,
  expanded,
  defaulted,
  onToggleAccordion,
}) => {
  return (
    <li
      className={`accordion-item ${expanded ? "expanded" : ""}`}
      onClick={onToggleAccordion}
    >
      <ExpandIcon expanded={expanded} />
      <div className="item-wrapper">
        <h5
          className={`${expanded ? "expanded" : ""} ${
            defaulted ? "default" : ""
          }`}
        >
          {title}
        </h5>
        <p
          className={`${expanded ? "expanded" : ""} ${
            defaulted ? "default" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </li>
  );
};

export default AccordionItem;
