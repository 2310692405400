import React, { useEffect, useRef } from "react";
import { Power1, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Subtitle from "../../common/subtitle";

import { ReactComponent as Logo } from "../../../assets/svg/logos/metaportal-logo-big.svg";
import background from "../../../assets/img/mail-notify-background.jpg";
import gaming01 from "../../../assets/img/gaming-01.png";
import gaming02 from "../../../assets/img/gaming-02.png";
import gaming03 from "../../../assets/img/gaming-03.png";
import xp01 from "../../../assets/img/xp-01.png";
import event01 from "../../../assets/img/event-01.png";
import event02 from "../../../assets/img/event-02.png";
import event03 from "../../../assets/img/event-03.png";

import "./styles.scss";

const Highlights = () => {
  const highlightsRef = useRef();
  const tl = useRef({});
  const q = gsap.utils.selector(highlightsRef);
  const width = window.innerWidth;
  const breakpoint = 1023;

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (width > breakpoint) {
      const trigger = highlightsRef.current;

      tl.current = gsap
        .timeline()
        .fromTo(
          q(".highlight-01-image-01"),
          {
            y: -120,
          },
          {
            y: 0,
            duration: 0.6,
            ease: Power1.easeOut,
            scrollTrigger: {
              trigger: trigger.querySelector(".highlight-01"),
              start: "top center",
              end: "bottom center",
              scrub: 1.5,
            },
          }
        )
        .fromTo(
          q(".highlight-01-image-02"),
          {
            y: 120,
          },
          {
            y: 0,
            duration: 0.6,
            ease: Power1.easeOut,
            scrollTrigger: {
              trigger: trigger.querySelector(".highlight-01"),
              start: "top center",
              end: "bottom center",
              scrub: 1.5,
            },
          }
        )
        .fromTo(
          q(".highlight-01-image-03"),
          {
            y: -120,
          },
          {
            y: 0,
            duration: 0.6,
            ease: Power1.easeOut,
            scrollTrigger: {
              trigger: trigger.querySelector(".highlight-01"),
              start: "top center",
              end: "bottom center",
              scrub: 1.5,
            },
          }
        )
        .fromTo(
          q(".highlight-02-image"),
          {
            y: 280,
          },
          {
            y: 0,
            duration: 0.6,
            ease: Power1.easeOut,
            scrollTrigger: {
              trigger: trigger.querySelector(".highlight-02"),
              start: "top center",
              end: "bottom center",
              scrub: 1.5,
            },
          }
        )
        .fromTo(
          q(".highlight-03-image-01"),
          {
            x: -120,
          },
          {
            x: 0,
            duration: 0.6,
            ease: Power1.easeOut,
            scrollTrigger: {
              trigger: trigger.querySelector(".highlight-03"),
              start: "top center",
              end: "bottom center",
              scrub: 1.5,
            },
          }
        )
        .fromTo(
          q(".highlight-03-image-02"),
          {
            y: 120,
          },
          {
            y: 0,
            duration: 0.6,
            ease: Power1.easeOut,
            scrollTrigger: {
              trigger: trigger.querySelector(".highlight-03"),
              start: "top center",
              end: "bottom center",
              scrub: 1.5,
            },
          }
        )
        .fromTo(
          q(".highlight-03-image-03"),
          {
            x: 120,
          },
          {
            x: 0,
            duration: 0.6,
            ease: Power1.easeOut,
            scrollTrigger: {
              trigger: trigger.querySelector(".highlight-03"),
              start: "top center",
              end: "bottom center",
              scrub: 1.5,
            },
          }
        );
    }
  });

  return (
    <section ref={highlightsRef}>
      <div className="top-wrapper">
        <div className="content highlights">
          <Logo />
          <h2>
            <strong>Games. Events. Frens.</strong>
          </h2>
          <p>Play, earn and compete in Web3 games with your friends.</p>
        </div>
      </div>
      <div className="sections">
        <div className="content">
          <div className="highlight-01">
            <div className="highlight-title">
              <Subtitle
                color="accent"
                emoji="&#127918;"
                text="play blockchain-based games"
                size="big"
              />
              <h3>
                Play All Your Favourite <strong>Games</strong>
              </h3>
              <p>
                Browse games from one easy to navigate and convenient interface.
                Easy to find, install and play.
              </p>
            </div>
            <div className="highlight-images">
              <img 
                src={background} 
                width={877}
                height={631}
                alt="background" 
                className="background" 
              />
              <img
                className="highlight-img highlight-01-image-01"
                src={gaming01}
                width={310}
                height={340}
                alt="Illuvium"
              />
              <img
                className="highlight-img highlight-01-image-02"
                src={gaming02}
                width={310}
                height={340}
                alt="Ember Sword"
              />
              <img
                className="highlight-img highlight-01-image-03"
                src={gaming03}
                width={310}
                height={340}
                alt="The sandbox"
              />
            </div>
          </div>
          <div className="highlight-02">
            <div className="highlight-title">
              <Subtitle
                color="subtle"
                emoji="&#128155;"
                text="in case you don't already love it"
                size="big"
              />
              <h3>
                <strong>Gain XP</strong> While Playing
              </h3>
              <p>Stay active, complete quests and get rewarded.</p>
            </div>
            <div className="highlight-wrapper">
              <ul>
                <li>
                  <span>&#11088;</span>Have fun while gaining XP
                </li>
                <li>
                  <span>&#11088;</span>Play every day for more rewards
                </li>
                <li>
                  <span>&#11088;</span>Play with friends{" "}
                  <Subtitle emoji="&#9203;" text="Coming later" />
                </li>
              </ul>
              <div className="highlight-image">
                <img
                  src={background} 
                  width={877}
                  height={631} 
                  alt="background" 
                  className="background" 
                />
                <img
                  className="highlight-img highlight-02-image"
                  src={xp01}
                  width={826}
                  height={606}
                  alt="Gain experience"
                />
              </div>
            </div>
          </div>
          <div className="highlight-03">
            <div className="highlight-events">
              <img 
                src={background} 
                width={877}
                height={631}
                alt="background" 
                className="background" 
              />
              <img
                className="highlight-img highlight-03-image-01"
                src={event01}
                width={253}
                height={337}
                alt="Event"
              />
              <img
                className="highlight-img highlight-03-image-02"
                src={event02}
                width={262}
                height={337}
                alt="Event"
              />
              <img
                className="highlight-img highlight-03-image-03"
                src={event03}
                width={253}
                height={337}
                alt="Event"
              />
            </div>
            <div className="highlight-title">
              <Subtitle
                color="purple"
                emoji="&#128467;"
                text="keep track of everything"
                size="big"
              />
              <h3>
                Never Miss an <strong>Event</strong>
              </h3>
              <p>
                Attend virtual concerts, conferences, and meetings wherever you
                are.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Highlights;
