import React from "react";

import Subtitle from "../../common/subtitle";

import { ReactComponent as Logo } from "../../../assets/svg/logos/enterdao-logo.svg";
import { ReactComponent as Twitter } from "../../../assets/svg/social/twitter.svg";
import { ReactComponent as Discord } from "../../../assets/svg/social/discord.svg";
import { ReactComponent as Medium } from "../../../assets/svg/social/medium.svg";
import DownloadLinksRow from "../../common/download-links-row";
import appHomeImage from "../../../assets/img/app-home.png";

import "./styles.scss";

const Title = () => {
  return (
    <section>
      <div className="content title">
        <h1>
          Your portal to <strong>the Metaverse</strong>
        </h1>
        <p>
          Your portal to all metaverse games and events. Never lose track of
          what's new in Web3.
        </p>
        <div className="badge">
          <Subtitle emoji="&#128293;" text="Out now" />
        </div>
        <img
          width={2880}
          height={1800}
          src={appHomeImage}
          className="hero-image"
          alt="MetaPortal is out now"
        />
        <DownloadLinksRow/>
        <div className="title-socials">
          <p>Follow us on Socials</p>
          <div>
            <a
              href="https://twitter.com/metaportal_gg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
            <a
              href="https://t.co/UBokVKGtwj"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Discord />
            </a>
            <a
              href="https://medium.com/enterdao"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Medium />
            </a>
          </div>
        </div>

        <a
          className="block"
          href="https://enterdao.xyz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Part of</span>
          <Logo />
        </a>
      </div>
    </section>
  );
};

export default Title;
