import { useMemo, useState } from "react"

const useCounter = (initialValue = 0, {min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY}) => {
  const [count, setCount] = useState(initialValue);

  const controls = useMemo(() => {
    return {
      increment: () => setCount(prevCount => {
        const nextCount = prevCount + 1;

        return nextCount > max ? max : nextCount
      }),
      decrement: () => setCount(prevCount => {
        const nextCount = prevCount - 1;

        return nextCount < min ? min : nextCount
      }),
      setCount
    }
  }, [min, max]);

  const isMin = count === min;
  const isMax = count === max;

  return {
    count,
    isMin, 
    isMax,
    ...controls
  }
}

export default useCounter;