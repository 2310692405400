import {
  ApolloClient,
  InMemoryCache
} from "@apollo/client";
import {
  AUTHENTICATE, CREATE_DOWNLOAD_INPUT,
  GET_DOWNLOADS_TODAY, UPDATE_DOWNLOAD_INPUT,
  GET_ALL_RELEASES
} from "./graphql-queries";

const graphqlClient = new ApolloClient({
  uri: process.env.REACT_APP_CMS_GRAPH_QL_ENDPOINT,
  cache: new InMemoryCache()
});

let isCurrentlyQuerying = false;

const getAuthToken = async () => {
  const { data } = await graphqlClient.mutate({
    mutation: AUTHENTICATE,
    variables: {
      username: process.env.REACT_APP_CMS_USER,
      password: process.env.REACT_APP_CMS_PASSWORD
    },
  });
  return data.authenticateUserWithPassword.token;
};

const getDownloadsForToday = async (formattedDate, authHeaders) => {
  const { data } = await graphqlClient.query({
    query: GET_DOWNLOADS_TODAY,
    variables: {
      where: {
        date: formattedDate
      }
    },
    context: { ...authHeaders }
  });
  return data.allAppDownloads;
};

const createNewDownloadInput = async (formattedDate, authHeaders) => {
  await graphqlClient.mutate({
    mutation: CREATE_DOWNLOAD_INPUT,
    variables: {
      data: {
        date: formattedDate,
        totalDownloads: 1
      }
    },
    context: { ...authHeaders }
  });
};

const updateDownloadsForInput = async (existingInput, authHeaders) => {
  await graphqlClient.mutate({
    mutation: UPDATE_DOWNLOAD_INPUT,
    variables: {
      id: existingInput.id,
      data: {
        totalDownloads: existingInput.totalDownloads + 1
      }
    },
    context: { ...authHeaders }
  });
}

export const updateDownloadsCount = async () => {
  // Make sure that multiple simultaneous download updates are not started
  if (isCurrentlyQuerying) {
    return;
  }
  isCurrentlyQuerying = true;

  // Clean up cache, so that the download input data is always correct
  await graphqlClient.resetStore();

  const token = await getAuthToken();
  const authHeaders = {
    headers: {
      authorization: `Bearer ${token}`
    }
  };

  // Generate formatted ISO Date string
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  today.toISOString()
  const todayISOString = today.toISOString();

  const dailyDownloads = await getDownloadsForToday(todayISOString, authHeaders);
  if (dailyDownloads.length === 0) {
    await createNewDownloadInput(todayISOString, authHeaders);
  } else {
    const existingInputForTheDay = dailyDownloads[0];
    await updateDownloadsForInput(existingInputForTheDay, authHeaders);
  }
  isCurrentlyQuerying = false;
}

export const getAllReleases = async () => {
  const token = await getAuthToken();
  const authHeaders = {
    headers: {
      authorization: `Bearer ${token}`
    }
  };

  const { data } = await graphqlClient.query({
    query: GET_ALL_RELEASES,
    context: { ...authHeaders }
  });
  return data.allReleases;
};
