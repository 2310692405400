import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const Subtitle = ({ wrapperClass, color, size, emoji, text }) => (
  <div className={`subtitle ${wrapperClass} ${color} ${size}`}>
    <span className="emoji">{emoji}</span>
    <p>{text}</p>
  </div>
);

Subtitle.propTypes = {
  wrapperClass: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  emoji: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

Subtitle.defaultProps = {
  wrapperClass: "",
  color: "accent",
  size: "normal",
};

export default Subtitle;
