import React, { useEffect, useState } from "react";
import Main from "../../components/common/main";
import Select from "../../components/common/select";
import { getAllReleases } from "../../services/downloads-service";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./styles.scss";

const Index = () => {
  const [allReleases, setAllReleases] = useState();
  const [selectedRelease, setSelectedRelease] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const allReleasesData = await getAllReleases();
      setAllReleases(allReleasesData);
      setSelectedRelease(allReleasesData[0]);
      return allReleasesData;
    }

    fetchData();
  }, []);

  useEffect(() => {
    ScrollTrigger.refresh();
    window.scrollTo(0, 0);
  }, [selectedRelease]);

  const renderBullets = (releaseNotes) => {
    if (releaseNotes) {
      const bullets = releaseNotes.split(/\r?\n/);
      return bullets.map((bullet, index) => <li key={index}>{bullet}</li>);
    }

    return [];
  };

  return (
    <Main>
      <section>
        <div className="content title">
          <h1>
            MetaPortal Release Notes
          </h1>
          <p>
            Select a version to see the new features and updates.
          </p>
          { selectedRelease && (
            <>
              <Select
                options={allReleases.map(d => d.version)}
                selected={selectedRelease}
                onChange={(a) => setSelectedRelease(allReleases.find(d =>d.version === a))}
              />
              <div className="panel">
                <p className="head">
                  <span className="emoji">&#9989; </span>
                  <strong>Updates in this version:</strong>
                </p>
                <ul className="list">
                  { renderBullets(selectedRelease.releaseNotes) }
                </ul>
              </div>
            </>
          ) }
        </div>
      </section>
    </Main>
  );
}

export default Index;
