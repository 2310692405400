import React from "react";
import useCounter from "../../hooks/useCounter";
import Button from "../common/button";
import Modal from "../common/modal";
import "./InstallationModal.styles.scss";
import clsx from "clsx";
import { installationSteps } from "./InstallationModal.config";
import { useInstallationModal } from "./InstallationModalProvider";
import Badge from "../common/badge/Badge";
import {ReactComponent as ExternalLinkIcon} from '../../assets/svg/external-link.svg';

const InstallationModal = () => {
  const installationModal = useInstallationModal();
  const steps = installationSteps[installationModal.os];
  const {
    count: activeStep,
    increment: nextStep,
    decrement: prevStep,
    setCount: setStep,
    isMax: isLastStep,
    isMin: isFirstStep,
  } = useCounter(0, { min: 0, max: steps.length - 1 });

  const { title, description, image } = steps[activeStep];

  const handleNextButtonClick = () => {
    if (isLastStep) {
      installationModal.closeInstallationModal();
    } else {
      nextStep();
    }
  };

  return (
    <Modal
      onClose={installationModal.closeInstallationModal}
      show={installationModal.opened}
      className="installation-modal"
    >
      {installationModal.opened && (
        <>
          <div className="installation-modal__image-container">
            <img 
              src={image}
              alt={title}
              className="installation-modal__image"
            />
          </div>
          <div className="installation-modal__content">
            <div className="installation-modal__header">
              <Badge color="blue">
                GETTING STARTED
              </Badge>

              <a
                className="installation-modal__learn-more-link" 
                target="_blank" 
                href="/"
              >
                Learn more <ExternalLinkIcon />
              </a>
            </div>
            <div className="installation-modal__body">
              <h4>{title}</h4>
              {description}
            </div>
            <div className="installation-modal__footer">
              <Button
                variant="solid"
                size="xs"
                color="grey"
                className={clsx("installation-modal__back-btn", {
                  "is-hidden": isFirstStep,
                })}
                onClick={prevStep}
              >
                Back
              </Button>

              <div className="installation-modal__pagination">
                {steps.map((step, i) => {
                  return (
                    <Button
                      key={i}
                      className={clsx(
                        "installation-modal__pagination-item",
                        i === activeStep && "is-active"
                      )}
                      onClick={() => setStep(i)}
                    />
                  );
                })}
              </div>

              <Button
                variant="solid"
                size="xs"
                color="blue"
                onClick={handleNextButtonClick}
              >
                {isLastStep ? "Got it" : "Next"}
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default InstallationModal;
