import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/svg/logos/metaportal-logo.svg";
import { ReactComponent as Apple } from "../../assets/svg/logos/apple.svg";
import { ReactComponent as Windows } from "../../assets/svg/logos/windows.svg";
import { updateDownloadsCount } from "../../services/downloads-service";

import "./styles.scss";
import { useInstallationModal } from "../installation-modal";

const HeaderDownloadLink = ({title, href, onClick, children}) => {
  const handleClick = (e) => {
    updateDownloadsCount();

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <a
      className="secondary"
      title={title}
      href={href}
      onClick={handleClick}
    >
      {children}
    </a>
  )
};

const Header = () => {
  const installationModal = useInstallationModal();

  return (
  <header>
    <div className="content">
      <Link to="/"><Logo /></Link>
      <div className="header-download-links">
        <span>Download App</span>
        <HeaderDownloadLink
          onClick={installationModal.openMacInstallationModal}
          title="Download for mac | intel"
          href={process.env.REACT_APP_DOWNLOAD_LINK_MAC_INTEL}
        >
          <Apple /> <span className="separator">|</span> Intel
        </HeaderDownloadLink>
        <HeaderDownloadLink
          onClick={installationModal.openMacInstallationModal}
          title="Download for mac | m1"
          href={process.env.REACT_APP_DOWNLOAD_LINK_MAC_M1}
        >
          <Apple /> <span className="separator">|</span> M1
        </HeaderDownloadLink>
        <HeaderDownloadLink
          onClick={installationModal.openWindowsInstallationModal}
          title="Download for windows"
          href={process.env.REACT_APP_DOWNLOAD_LINK_WINDOWS}
        >
          <Windows />
        </HeaderDownloadLink>
      </div>
    </div>
  </header>
  )
}

export default Header;
