import React, { useState } from "react";
import AccordionItem from "../../common/accordion-item";

import "./styles.scss";

const questions = [
  {
    id: "faq1",
    question: "What is Metaportal?",
    answer:
      "MetaPortal is a cross-platform desktop application, serving as a single point of access to Web3 games and metaverse events. It is a product built by EnterDAO and is governed by the ENTR token and its community.",
  },
  {
    id: "faq2",
    question: "What can I do within MetaPortal?",
    answer:
      "You can browse and play a variety of blockchain-based games and enter metaverse events. You gain XP when you complete quests and stay active.",
  },
  {
    id: "faq3",
    question: "Do I need a wallet to use MetaPortal?",
    answer:
      "If you already have an existing wallet, you can use it to log into MetaPortal. If you don't have one - don't worry, you'll be able to create a wallet through MetaPortal. <br /> The wallet will serve as a unique identifier (instead of a username). It is non-custodial, lets you store your digital assets, and gives you complete control over them. It serves as a gateway to the web3 world and allows you to use those assets in all web3 games and metaverses.",
  },
  {
    id: "faq4",
    question: "How can I recover my account?",
    answer:
      "You can recover your account with your wallet's private key. Make sure to save your seed phrase and create a backup of your wallet through the Profile page.",
  },
];

const Faq = () => {
  const [setActive, setActiveState] = useState("");

  function toggleAccordion(item) {
    setActiveState(item);
  }

  function resetAccordion() {
    setActiveState("");
  }

  return (
    <section className="FAQ">
      <div className="content-wrapper">
        <h2>
          Frequently Asked <strong>Questions</strong>
        </h2>
        <ul className="accordion-wrapper">
          {questions.map((item) => (
            <AccordionItem
              key={item.id}
              title={item.question}
              content={item.answer}
              expanded={setActive === item.id ? true : false}
              defaulted={setActive === ""}
              onToggleAccordion={
                setActive === item.id
                  ? () => resetAccordion()
                  : () => toggleAccordion(item.id)
              }
            />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Faq;
