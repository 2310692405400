import React from "react";
import {
  Switch,
  Route,
  BrowserRouter
} from "react-router-dom";
import ScrollToTop from "./components/common/scroll-to-top";
import Home from "./pages/Home";
import ReleaseNotes from "./pages/ReleaseNotes";
import { InstallationModalProvider } from "./components/installation-modal";
 
const App = () => {
  return (
    <InstallationModalProvider>
      <div className="main" id="metaportal">
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/release-notes' exact component={ReleaseNotes} />
            <Route path='/discord' component={() => {
              const queryParams = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
              });
              let code = queryParams.code;
              window.location.href = `metaportal://?code=${code}`;
              return null;
            }}/>
            <Route path='/*' exact component={Home} />
          </Switch>
        </BrowserRouter>
      </div>
    </InstallationModalProvider>
  );
}

export default App;
